export class Hotel {

    constructor(public name: string, 
                public city: string,
                public logo: string, 
                public phone: string,
                public description: string,
                public class_id: string) {

        
    }
}