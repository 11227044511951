export class Client {


    
    constructor(
        public sex: string,
        public nationality: string,
        public firstname: string, 
        public lastname: string, 
        public phone: string,
        public passport_number: string,
        public remark: string) {

        
    }
}